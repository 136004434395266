<template>
  <div class="card">
    <div class="card-body">
      <div class="col-12 mt-2">
        <div
          v-if="alert"
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
        >
          {{ alertText }}
        </div>
        <h4 class="card-title mb-5">Report</h4>
        <div
          style="display:flex;justify-content:space-between;align-items:center;"
        >
          <div style="width:50%;margin-left:10px">
            <b-form-group
              required
              id="input-group-2"
              label="Start Date *"
              label-for="input-2"
            >
              <b-form-datepicker
                v-model="startDate"
                class="mb-2"
              ></b-form-datepicker>
            </b-form-group>
          </div>

          <div style="width:50%;margin-left:10px">
            <b-form-group
              required
              id="input-group-2"
              label="End Date *"
              label-for="input-2"
            >
              <b-form-datepicker
                v-model="endDate"
                class="mb-2"
              ></b-form-datepicker>
            </b-form-group>
          </div>
        </div>

        <div
          style="display:flex;justify-content:space-between;align-items:center;"
        >
          <div style="width:50%;margin-left:10px" v-if="showRestaurantUserDiv">
            <b-form-group
              id="input-group-3"
              label="Restaurant Users *"
              label-for="input-3"
            >
              <multiselect
                track-by="id"
                :custom-label="restaurantUserLable"
                label="username"
                v-model="restaurantuser"
                :options="restaurantusers"
                placeholder="Select Restaurant"
                @select="onSelectRestaurantUser"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.username }} - ({{ option.email }})</strong
                  ></template
                >
              </multiselect>
            </b-form-group>
          </div>
          <div style="width:50%;margin-left:10px">
            <b-form-group
              id="input-group-3"
              label="Restaurant *"
              label-for="input-3"
            >
              <multiselect
                track-by="id"
                :custom-label="customLabel"
                label="username"
                v-model="restaurant"
                :options="restaurants"
                placeholder="Select Restaurant"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.name }} - ({{ option.location }})</strong
                  ></template
                >
              </multiselect>
            </b-form-group>
          </div>
        </div>

        <div
          style="display:flex;justify-content:space-between;align-items:center;"
        >
          <div style="width:50%;margin-left:10px">
            <b-form-group
              id="input-group-3"
              label="Payment Type"
              label-for="input-3"
            >
              <b-form-select
                id="input-3"
                v-model="paymentType"
                :options="paymentTypes"
              ></b-form-select>
            </b-form-group>
          </div>
          <div style="width:50%;margin-left:10px">
            <b-form-group
              id="input-group-3"
              label="Order Status "
              label-for="input-3"
            >
              <b-form-select
                id="input-3"
                v-model="orderStatus"
                :options="orderStatuses"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div style="display:flex;align-items:center;">
          <div style="width:10%;padding-left:10px">
            <a class="btn btn-success" @click="searcResultApi()">Search</a>
          </div>
          <div style="width:10%;padding-left:10px">
            <a class="btn btn-success" @click="clearFiled()">Clear</a>
          </div>
          <div style="width:20%;padding-left:10px">
            <a class="btn btn-success" @click="generatePDF()">Generate Pdf</a>
          </div>
        </div>
        <hr />
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height:100vh"
        >
          <img
            :src="'./images/loader.gif'"
            class="mr-2"
            style="width:100px;height:100px"
          />
        </div>

        <div class="loading" v-if="!loading">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div>
            <div class="table-responsive mb-0">
              <b-table
                id="table_id"
                :items="loans"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                ref="selectableTable"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                empty-text="No record found"
                empty-filtered-text="No search result found"
              >
                <template v-slot:cell(id)="meunItemsTempArray">
                  <input
                    type="checkbox"
                    :value="meunItemsTempArray.item.id"
                    v-model="checkedNames"
                  />
                </template>

                <template v-slot:cell(order_status)="loans">
                  <p>
                    {{
                      loans.item.order_status.charAt(0).toUpperCase() +
                        loans.item.order_status.substring(1)
                    }}
                  </p>
                </template>
              </b-table>
            </div>
          </div>
          <div
            style="    background: #000;
    color: #ffff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;"
          >
            <span
              ># {{ this.startPage }}-{{ this.endPage }} to
              {{ this.loans.length }}</span
            >
            <span
              >Total Orders :
              <span style="margin-left:5px">{{ this.loans.length }}</span></span
            >
            <span
              >Pickup orders :
              <span style="margin-left:5px">{{
                this.pickUpOrderAmount.toFixed(2)
              }}</span>
              €</span
            >
            <span
              >Delivery Orders :
              <span style="margin-left:5px">{{
                this.onLineOrderAmount.toFixed(2)
              }}</span>
              €</span
            >
            <span
              >Total Amount :
              <span style="margin-left:5px">{{
                this.totalPriceOfOrders.toFixed(2)
              }}</span>
              €</span
            >
          </div>
        </div>
        <br /><br />

        <body style="display:none">
          <div id="page_heading">
            <div
              style="display: flex;justify-content: space-between;align-items: center;"
            >
              <div>
                <h2>Invoice</h2>
                <p>{{ this.restaurant.name }}</p>
                <p>{{ this.restaurant.location }}</p>
              </div>
              <!-- <div>
                <img
                  width="300"
                  src="http://localhost:8080/media/logos/logo-light.png"
                  alt=""
                />
              </div> -->
            </div>
            <p>Customer id: {{ this.restaurantuser.id }}</p>
            <div
              style="display: flex;align-items: center;justify-content: space-between;max-width: 500px;"
            >
              <p>
                <span style="padding-right: 20px;">Date:</span
                ><span>17-08-2021</span>
              </p>
              <p>
                <span style="padding-right: 20px;">Invoice number:</span
                ><span>1293456790</span>
              </p>
            </div>
            <p style="border-bottom: 1px solid #000;padding-bottom: 10px;">
              <!-- Folgende Leistungen stellen wir Ihnen in Rechnung: -->
            </p>
            <p>
              GutenAppetite ({{ this.startDate }} up to and including
              {{ this.endDate }}): {{ this.loans.length }} orders with a value
              of {{ this.totalPriceOfOrders.toFixed(2) }}
            </p>
            <div
              style="display: flex;justify-content: space-between;align-items: center;"
            >
              <span
                >Service fee: {{ this.restaurant.guten_percentage }} % of
                {{ this.totalPriceOfOrders.toFixed(2) }}</span
              >
              <span>{{ this.gutenPercentageCalculation }}</span>
            </div>
            <p>
              administration fee (Online payments) ({{ this.startDate }} until
              including {{ this.endDate }}):
              {{ this.onLineOrders.length }} Orders in {{ this.onLineAmount }}
            </p>
            <div
              style="display: flex;justify-content: space-between;align-items: center;"
            >
              <p style=" margin: 8px 0px;">
                Stripe fee: {{ this.restaurant.stripe_fee }} x
                {{ this.onLineOrders.length }}
              </p>
              <p
                style="border-bottom: 1px solid #000;padding-bottom: 5px;margin: 8px 0px;"
              >
                {{ this.stripFeeCalcultion }}
              </p>
            </div>
            <div
              style="display: flex;justify-content: space-between;align-items: center;"
            >
              <p style=" margin: 8px 0px;">Subtotal</p>
              <p style=" margin: 8px 0px;">
                {{ this.subTotalGutenPerStripeFree }}
              </p>
            </div>
            <div
              style="display: flex;justify-content: space-between;align-items: center;"
            >
              <p style=" margin: 8px 0px;">
                Tax. ({{ this.restaurant.tax }}% of €
                {{ this.subTotalGutenPerStripeFree }})
              </p>
              <p
                style="border-bottom: 1px solid #000;padding-bottom: 5px; margin: 8px 0px;"
              >
                {{ this.taxCalculation }}
              </p>
            </div>
            <div
              style="display: flex;justify-content: space-between;align-items: center;"
            >
              <p style=" margin: 8px 0px;">Total amount of this invoice</p>
              <p style=" margin: 8px 0px;">
                {{ this.subTotalGutenPerStripeFreeTax }}
              </p>
            </div>

            <div
              style="display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #000;padding-bottom: 10px;"
            >
              <!-- <h4 style=" margin: 8px 0px;">Total Orders Amount</h4>
              <h4 style=" margin: 8px 0px;">€ 322,51</h4> -->
            </div>
            <p>
              Your turnover in the period from {{ this.startDate }} up to and
              including {{ this.endDate }} .
              {{ this.totalPriceOfOrders.toFixed(2) }}
            </p>

            <!-- <span>Benutzername:</span> -->
            <!-- <span style="padding-left: 30px;">restaurantta</span> -->
            <h4 style="border-top: 1px solid #000;padding-top: 10px;">
              <!-- yd.yourdelivery GmbH -->
            </h4>
            <div
              style="display: flex;justify-content:space-between;flex-wrap:nowrap"
              class="bottom"
            >
              <!-- <div>
                <p>Cuvrystr. 50, 52, 54 /</p>
                <p>Schlesische</p>
                <p>0997 Berlin</p>
                <p>Deutschland</p>
              </div>
              <div>
                <p>Geschäftsführerin</p>
                <p>Katharina Hauke</p>
                <p>info@lieferando.de</p>
                <p>Deutschland</p>
              </div>
              <div>
                <p>Cuvrystr. 50, 52, 54 /</p>
                <p>Schlesische</p>
              </div>
              <div>
                <p>Amtsgericht Berlin-Charlottenburg</p>
                <p>Schlesische</p>
                <p>0997 Berlin</p>
              </div> -->
            </div>
            <div>
              <h2>Single listing</h2>
              <p>
                <span style="padding-right: 20px;">Restaurant Name:</span>
                <span>{{ this.restaurant.name }} </span>
              </p>
              <p>
                <span style="padding-right: 20px;">Period</span>
                <span
                  >{{ this.startDate }} up to and including
                  {{ this.endDate }}</span
                >
              </p>
            </div>
            <div
              style="display: flex;justify-content: space-between;max-width: 500px;"
            >
              <p>Total</p>
              <p>
                {{ this.loans.length }} Orders worth
                {{ this.totalPriceOfOrders.toFixed(2) }}
              </p>
            </div>
            <div
              style="display: flex;justify-content: space-between;max-width: 500px;"
            >
              <p>Paid on Cash</p>
              <p>
                {{ this.paidOnCash.length }} Orders worth €
                {{ this.paidOnCashAmount }}
              </p>
            </div>
            <div
              style="display: flex;justify-content: space-between;max-width: 500px;"
            >
              <p>paid online *</p>
              <p>
                {{ this.onLineOrders.length }} Orders worth €
                {{ this.onLineAmount }}
              </p>
            </div>

            <div style="border-bottom: 1px solid #000;"></div>
            <div
              style="display: flex;justify-content: space-between;align-items: center;"
            >
              <p style=" margin: 8px 0px;">
                Outstanding online payments on 17-08-2021 *
              </p>
              <p
                style="border-bottom: 1px solid #000;padding-bottom: 5px;margin: 8px 0px;"
              >
                {{ this.onLineAmount }}
              </p>
            </div>
            <div
              style="display: flex;justify-content: space-between;align-items: center;"
            >
              <p style=" margin: 8px 0px;">Invoice Id 129342268</p>
              <p
                style="border-bottom: 1px solid #000;padding-bottom: 5px;margin: 8px 0px;"
              >
                {{ this.subTotalGutenPerStripeFreeTax }}
              </p>
            </div>
            <div
              style="display: flex;justify-content: space-between;align-items: center;"
            >
              <p style=" margin: 8px 0px;">
                Total Amout Paid to Restaurant
              </p>
              <h4 style="margin: 0;">
                {{ this.onLineAmount - this.subTotalGutenPerStripeFreeTax }}
              </h4>
            </div>
            <div
              style="display: flex;justify-content: space-between; padding-top: 20px;"
            ></div>
            <div>
              <h2>Single listing</h2>
              <p>
                <span style="padding-right: 20px;">Restaurant Name:</span>
                <span>{{ this.restaurant.name }} </span>
              </p>
              <p>
                <span style="padding-right: 20px;">Period</span>
                <span
                  >{{ this.startDate }} up to and including
                  {{ this.endDate }}</span
                >
              </p>
            </div>
            <div>
              <table id="bootstrap_table">
                <tr>
                  <th>Date</th>
                  <th>#</th>
                  <th>€</th>
                  <th>Order status</th>
                </tr>

                <tr v-for="loan in loans" :key="loan.id">
                  <td>{{ loan.date }} {{ loan.time }}</td>
                  <td>{{ loan.id }}</td>
                  <td>{{ loan.totalprice }}</td>
                  <td>
                    {{ capitalizeFirstLetter(loan.order_status) }}
                  </td>
                </tr>
              </table>
            </div>
            <div
              style="border-bottom: 1px solid #000; padding: 20px 0px;"
            ></div>
            <p>
              **** Der ausstehende Kontostand besteht aus sämtlichen erhaltenen
              Onlinezahlungen abzüglich der Rückerstattungen, Einzahlungen für
              den Ausgleich von Rechnungen, sowie Auszahlungen. Diese
              Informationen finden Sie unter https://restaurants.takeaway.com/
            </p>
          </div>
        </body>

        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/0.9.0rc1/jspdf.min.js"></script>
<script>
import store from "@/core/services/store/index.js";
// import Vue from "vue";

// import moment from "moment";
// Vue.prototype.moment = moment;
import axios from "axios";
import Multiselect from "vue-multiselect";
// import { jsPDF } from "jspdf";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import html2pdf from "html2pdf.js";
import autoTable from "jspdf-autotable";
// import domtoimage from "dom-to-image";
import $ from "jquery";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      restaurants: [],
      showRestaurantUserDiv: false,
      changeClasss: false,
      subTotalGutenPerStripeFree: 0,
      subTotalGutenPerStripeFreeTax: 0,
      taxCalculation: 0,
      stripFeeCalcultion: 0,
      gutenPercentageCalculation: 0,
      alert: false,
      pickUpOrderAmount: 0,
      paidOnDelivery: 0,
      paidOnDeliveryAmount: 0,

      paidOnCash: 0,
      paidOnCashAmount: 0,
      onLineOrders: 0,
      numberOfOrders: 0,
      totalPriceOfOrders: 0,
      onLineOrderAmount: 0,
      onLineAmount: 0,
      alertText: "",
      restaurant: "",
      restaurantuser: "",
      restaurantusers: [],
      loans: [],
      tempArray: [],
      newArray: [],
      loading: false,
      startDate: "",
      startPage: 0,
      endPage: 0,
      endDate: "",
      isSelectAll: false,
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        { key: "date", label: "Date" },
        { key: "restaurant.name", label: "Restaurant Name" },
        { key: "totalprice", label: "Price €" },
        { key: "ordertype", label: "Order Type" },
        { key: "order_status", label: "Order status" },
        { key: "paymentTypeText", label: "Payment Type" },
        { key: "paymentStatusText", label: "Payment Status" },
      ],
      paymentType: "",
      paymentTypes: [
        { text: "Select Payment Type", value: "" },
        { text: "Online", value: "1" },
        { text: "Cash", value: "0" },
      ],
      orderStatus: "",
      orderStatuses: [
        { text: "Select Order Status", value: "" },
        { text: "Pending", value: "pending" },
        { text: "Accepted", value: "accepted" },
        { text: "Rejected", value: "rejected" },
        { text: "Cooking", value: "cooking" },
        { text: "Ready to pickup", value: "readytopickup" },
        { text: "Dispatched", value: "dispatched" },
        { text: "Completed", value: "completed" },
      ],
    };
  },

  mounted() {
    document.title = "Report";

    if (
      store.state.loginUserType == "superadmin" ||
      store.state.loginUserType == "admin"
    ) {
      this.showRestaurantUserDiv = true;
    }
    // this.getRecord();
    this.getAllRestaurant();
    this.getRestaurnatUser();
    this.isSelectAll = this.unSelectedCheckbox;
  },

  computed: {
    rows() {
      return this.loans.length;
    },
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    generatePDF() {
      var doc = new jsPDF();
      doc.setFontType("bold");
      doc.setFontSize(20);
      doc.text(10, 10, "Invoice");
      doc.setFontType("normal");
      doc.setFontSize(11);
      doc.text(10, 20, this.restaurant.name);
      doc.text(10, 28, this.restaurantuser.username);
      doc.text(10, 36, this.restaurant.location);
      doc.text(10, 44, "Customer Id");
      doc.text(60, 44, this.restaurantuser.id.toString());
      doc.text(10, 52, "Date");
      doc.text(60, 52, "2021-08-23");
      doc.text(10, 60, "Invoice Id");
      doc.text(60, 60, "123456789");
      doc.setFontType("bold");
      doc.setFontSize(21);
      doc.text(130, 30, "Guten Appetite");
      // doc.text(image, "PNG", 150, 10, 40, 25);
      // var image = new Image();
      // image.src = "./images/logo.png";
      // // var logo_url = "./images/burger.jpg";
      // // var logo_url = "data:image/jpg;base64," + btoa("./images/burger.jpg");
      // doc.addImage(image, "PNG", 150, 10, 40, 25);
      // // x-coordinate, y-coordinate, width, height
      // doc.setFontType("normal");
      doc.setFontType("normal");
      doc.setFontSize(11);
      doc.text(
        10,
        68,
        "GutenAppetite ( " +
          this.startDate +
          " up to and including " +
          this.endDate +
          " ): " +
          this.tempArray.length +
          " orders with a value of € " +
          this.totalPriceOfOrders.toFixed(2)
      );
      doc.line(10, 72, 160, 72);
      doc.text(
        10,
        80,
        "Service fee: " +
          this.restaurant.guten_percentage.toString() +
          " % of € " +
          this.totalPriceOfOrders.toFixed(2)
      );

      doc.text(180, 80, "€ " + this.gutenPercentageCalculation.toString());
      doc.text(
        10,
        88,
        "administration fee (Online payments) ( " +
          this.startDate +
          " until including " +
          this.endDate +
          "): " +
          this.onLineOrders.length +
          " Orders in € " +
          this.onLineAmount
      );

      doc.text(
        10,
        96,
        "Stripe fee: " +
          this.restaurant.stripe_fee +
          " x " +
          this.onLineOrders.length
      );

      doc.text(180, 96, "€ " + this.stripFeeCalcultion.toString());

      doc.text(10, 104, "Subtotal");

      doc.text(180, 104, "€ " + this.subTotalGutenPerStripeFree.toString());

      doc.text(
        10,
        112,
        "Tax. " +
          this.restaurant.tax +
          "(% of € " +
          this.subTotalGutenPerStripeFree +
          ")"
      );

      doc.text(180, 112, "€ " + this.taxCalculation);

      doc.text(10, 120, "Total amount of this invoice");
      doc.text(180, 120, " € " + this.subTotalGutenPerStripeFreeTax);
      doc.line(10, 124, 160, 124);
      doc.text(
        10,
        132,
        "Your turnover in the period from " +
          this.startDate +
          " up to and including " +
          this.endDate +
          " € " +
          this.totalPriceOfOrders.toFixed(2)
      );
      doc.line(10, 136, 160, 136);

      doc.setFontType("bold");
      doc.setFontSize(20);
      doc.text(10, 150, "Single Listing");
      doc.setFontType("normal");
      doc.setFontSize(11);
      doc.text(10, 160, "Restaurant Name:");
      doc.text(60, 160, this.restaurant.name);
      doc.text(10, 168, "Period");
      doc.text(
        60,
        168,
        "" + this.startDate + " up to and including " + this.endDate
      );
      doc.text(10, 176, "Total");
      doc.text(
        60,
        176,
        this.tempArray.length +
          " Orders worth " +
          this.totalPriceOfOrders.toFixed(2)
      );
      doc.text(10, 184, "Paid on Cash");

      doc.text(
        60,
        184,
        this.paidOnCash.length + " Orders worth € " + this.paidOnCashAmount
      );

      doc.text(10, 192, "Paid online *");
      doc.text(
        60,
        192,
        this.onLineOrders.length + " Orders worth € " + this.onLineAmount
      );

      doc.line(10, 196, 160, 196);

      doc.text(10, 204, "Outstanding online payments on 23-08-2021 *");

      doc.text(180, 204, "€ " + this.onLineAmount);

      doc.text(10, 212, "Invoice Id 129342268");

      doc.text(180, 212, " € " + this.subTotalGutenPerStripeFreeTax);

      doc.text(10, 220, "Total Amout Paid to Restaurant");

      doc.text(
        180,
        220,
        "€ " +
          (this.onLineAmount - this.subTotalGutenPerStripeFreeTax).toFixed(2)
      );
      doc.setFontType("bold");
      doc.setFontSize(20);
      doc.text(10, 240, "Single Listing");
      doc.setFontType("normal");
      doc.setFontSize(11);
      doc.text(10, 250, "Restaurant Name:");
      doc.text(60, 250, this.restaurant.name);
      doc.text(10, 260, "Period");
      doc.text(
        60,
        260,
        this.startDate + " up to and including " + this.endDate
      );
      doc.addPage(); // add new page in pdf

      autoTable(doc, { html: "#bootstrap_table" });

      doc.setTextColor(255, 0, 0); //set font color to red
      // doc.text(60, 20, "Sharif"); //append first name in pdf
      // doc.text(100, 20, "Sharif"); //append last name in pdf
      // doc.addPage(); // add new page in pdf
      // doc.setTextColor(165, 0, 0);
      // doc.text(10, 20, "extra page to write");
      doc.save("Report.pdf"); // Save the PDF with name "katara"...
    },

    clearFiled() {
      this.startDate = "";
      this.endDate = "";
      this.restaurant = "";
      this.restaurantuser = "";
      this.orderStatus = "";
      this.paymentType = "";
    },

    onSelectRestaurantUser(option) {
      (this.restaurant = ""), (this.restaurants = []);
      this.restaurants = option.restaurnats;
    },

    searcResultApi() {
      this.loans = [];
      if (this.startDate == "") {
        this.validationMessage("Please select Start Date");
        return false;
      }
      if (this.endDate == "") {
        this.validationMessage("Please select End Date");
        return false;
      }
      if (this.showRestaurantUserDiv) {
        if (this.restaurantuser == "") {
          this.validationMessage("Please select Restaurant User");
          return false;
        }
      }
      if (this.restaurant == "") {
        this.validationMessage("Please select Restaurant");
        return false;
      }

      this.loading = true;
      this.alert = false;
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      const data = {
        start_date: this.startDate,
        end_date: this.endDate,
        restaurant_user: this.restaurantuser.id,
        restaurant_id: this.restaurant.id,
        payment_type: this.paymentType,
        order_status: this.orderStatus,
      };
      try {
        axios
          .post(this.$path + "/user/reporting", data, {
            headers,
          })
          .then((response) => {
            this.tempArray = [];
            this.startPage = 1;
            this.endPage = this.perPage;
            this.loans = response.data.data;
            this.tempArray = this.loans.filter((item) => {
              return item.payment_status == "1";
            });
            this.totalPriceOfOrders = this.tempArray.reduce(
              (acc, item) => acc + item.totalprice,
              0
            );

            this.paidOnDelivery = this.tempArray.filter((item) => {
              return item.ordertype.toString() === "Delivery";
            });

            this.paidOnCash = this.tempArray.filter((item) => {
              return item.payment_type === "0";
            });

            this.paidOnCashAmount = this.paidOnCash
              .reduce((acc, item) => acc + item.totalprice, 0)
              .toFixed(2);

            this.paidOnDeliveryAmount = this.paidOnDelivery
              .reduce((acc, item) => acc + item.totalprice, 0)
              .toFixed(2);

            this.cashOrders = this.tempArray.filter((item) => {
              return item.payment_type === "0";
            });
            this.onLineOrders = this.tempArray.filter((item) => {
              return item.payment_type === "1";
            });

            this.onLineAmount = this.onLineOrders
              .reduce((acc, item) => acc + item.totalprice, 0)
              .toFixed(2);

            this.gutenPercentageCalculation = (
              (this.restaurant.guten_percentage / 100) *
              this.totalPriceOfOrders
            ).toFixed(2);

            this.stripFeeCalcultion =
              this.restaurant.stripe_fee * this.onLineOrders.length;

            this.subTotalGutenPerStripeFree = (
              parseFloat(this.gutenPercentageCalculation) +
              parseFloat(this.stripFeeCalcultion)
            ).toFixed(2);

            this.taxCalculation = (
              (this.restaurant.tax / 100) *
              this.subTotalGutenPerStripeFree
            ).toFixed(2);

            this.subTotalGutenPerStripeFreeTax = (
              parseFloat(this.taxCalculation) +
              parseFloat(this.subTotalGutenPerStripeFree)
            ).toFixed(2);

            var start = (this.currentPage - 1) * this.perPage + 1;

            this.onLineOrderAmount = this.tempArray.reduce(function(
              sum,
              record
            ) {
              return record.ordertype.toString() === "Pickup"
                ? sum
                : sum + record.totalprice;
            },
            0);

            this.pickUpOrderAmount = this.tempArray.reduce(function(
              sum1,
              record
            ) {
              return record.ordertype.toString() === "Delivery"
                ? sum1
                : sum1 + record.totalprice;
            },
            0);

            // console.log(this.pickUpOrderAmount);

            this.loading = false;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    findorderStatus() {
      return this.tempArray.filter((item) => {
        return item.order_status == this.orderStatus;
      });
    },
    findendDate() {
      let allCampaigns;
      allCampaigns = this.tempArray.map(
        (item) => item.order_status === this.orderStatus
      );
      return allCampaigns;
    },

    // searchData() {
    //   if (this.orderStatus) {
    //     return this.tempArray.filter((item) => {
    //       return item.order_status == this.orderStatus;
    //     });
    //   } else {
    //     return this.tempArray;
    //   }
    // },

    filteredPeople() {
      return this.tempArray
        .filter((item) => item.date >= this.startDate)
        .filter((item) => item.date <= this.endDate)
        .filter((item) => item.order_status === this.orderStatus);
    },

    filteredItems: function() {
      return this.tempArray.filter(function(item) {
        return item.date >= this.startDate || item.date <= this.endDate;
      });
    },

    // filteredPeople() {
    //   return this.tempArray.filter(
    //     (item) => item.order_status === this.orderStatus
    //   );
    // },

    filteredData() {
      const res = this.tempArray.filter((item) => {
        if (this.startDate) if (item.date < this.startDate) return false;

        if (this.endDate) if (item.date > this.endDate) return false;

        if (this.restaurant)
          if (item.restaurant.id !== this.restaurant.id) return false;

        if (this.restaurantuser)
          if (item.restaurant.customer_id !== this.restaurantuser.id)
            return false;

        if (this.paymentType)
          if (item.payment_type !== this.paymentType) return false;

        if (this.orderStatus)
          if (
            item.order_status.trim().toLowerCase() !==
            this.orderStatus.trim().toLowerCase()
          )
            return false;

        return true;
      });
      return res;
    },

    searchResult() {
      this.loans = this.filteredData();
    },
    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },

    getAllRestaurant() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/restaurant/list", "", {
            headers,
          })
          .then((response) => {
            this.restaurants = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getRestaurnatUser() {
      const data = { type: "restaurant" };
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/list", data, {
            headers,
          })
          .then((response) => {
            this.restaurantusers = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    customLabel({ name, location }) {
      return `${name}—(${location})`;
    },
    restaurantUserLable({ username, email }) {
      return `${username}—(${email})`;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    singlActiveDeactive(object) {
      this.$emit("showModal", true, object);
    },

    deleterRecord(object) {
      this.$emit("deleteData", object);
    },

    editData(object) {
      this.$emit("editData", object);
    },

    orderDetail(object) {
      this.$emit("orderDetail", object);
    },

    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
  },

  watch: {
    currentPage: function(value) {
      this.startPage = parseInt((value - 1) * this.perPage);
      if (this.startPage == 0) {
        this.startPage = 1;
      }
      this.endPage = parseInt(value * this.perPage);
    },

    perPage: function(value) {
      this.startPage = parseInt((this.currentPage - 1) * value);
      if (this.startPage == 0) {
        this.startPage = 1;
      }
      this.endPage = parseInt(this.currentPage * value);
    },
  },
};
</script>

<style scoped>
.table-responsive {
  overflow-x: visible !important;
}
.btn-success {
  border-color: #5cb700;
  background-color: rgb(92, 183, 0);
}
.btn.btn-success:hover {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
.btn-info {
  background-color: #697165;
  border-color: #697165;
}
.btn-info:hover {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
.btn-primary {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:hover {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:active {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:active {
  border-color: #697165 !important;
  background-color: #697165 !important;
}

.btn-primary:focus {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:focus {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
body {
  margin: 0;
  padding: 30px;
  box-sizing: border-box;
}

.bottom p {
  margin: 8px 0px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
thead {
  display: table-header-group;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
tr {
  page-break-inside: avoid;
}

tr:nth-child(even) {
  background-color: #dddddd;
  page-break-inside: avoid;
}
</style>
